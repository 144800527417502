import React from "react"
import Layout from "../../components/layout"
import { useStaticQuery, graphql, Link } from "gatsby"

const BlogIndex = () => {
  const data = useStaticQuery(graphql`
    {
      allMdx(
        filter: {
          fileAbsolutePath: { glob: "**/blog/*" }
          frontmatter: { draft: { ne: true } }
        }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              excerpt
              date
            }
            timeToRead
            slug
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <div className="px-10 py-5">
        <ul>
          {data.allMdx.edges.map(({ node }, index) => (
            <Link key={index} className="no-underline" to={`/${node.slug}`}>
              <li className="mb-9">
                <h2 className="text-3xl">{node.frontmatter.title}</h2>
                <h3 className="text-xl">
                  {new Date(node.frontmatter.date).toDateString()}
                </h3>
                <p className="mt-2 max-w-xl">
                  {node.frontmatter.excerpt}{" "}
                  <span className="underline">Read more</span>
                </p>
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export default BlogIndex
